import React, {
  createContext,
  ReactNode,
  useRef,
  useState,
  useEffect,
  FC,
} from "react";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

type Props = {
  children: ReactNode;
};

export interface authTypes {
  session: {
    username?: string;
  };
  Login: any;
  SignOut: () => {};
  confirmPassword: (email: string, password: string, code: string) => {};
  forgotPassword: (email: string) => {};
  signUpError: string;
  SignUp: any;
  loginError: string;
  signupSuccess: boolean;
  setSignUpError: any;
  setSignupSuccess: any;
  loginErrorMessage: string;
  confirmError: string;
  attributes: any;
  getUserDetails: () => {};
  updateUserAttributes: any;
  userPool: any;
}
export const Context = createContext({} as any);

export const Provider: FC<Props> = ({ children }) => {
  var poolData = {
    UserPoolId: "eu-west-2_j23yOC4en",
    ClientId: "768uk2rubl81vq8llfmljguo1o",
  };
  const userPool = useRef(new CognitoUserPool(poolData));
  const [session, setSession] = useState<any>();
  const [loginError, setLoginError] = useState<any>(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [signUpError, setSignUpError] = useState<any>(null);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [confirmError, setConfirmError] = useState("");
  const [attributes, setAttributes] = useState<any>();

  const Login = async (email: string, password: string) => {
    try {
      var userData = {
        Username: email,
        Pool: userPool.current,
      };
      var authenticationData = {
        Username: email,
        Password: password,
      };
      var cognitoUser = new CognitoUser(userData);
      var authenticationDetails = new AuthenticationDetails(authenticationData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          result.getAccessToken().getJwtToken();
          const user = userPool.current.getCurrentUser();
          getUserDetails()
          setSession(user);
        },
        onFailure: function (err) {
          setLoginErrorMessage(err.message || JSON.stringify(err));
          setLoginError(true);
        },
      });
    } catch (err: any) {
      console.log(err.message);
      setLoginErrorMessage(err.message);
      setLoginError(true);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      var userData = {
        Username: email,
        Pool: userPool.current,
      };
      var cognitoUser = new CognitoUser(userData);
      cognitoUser.forgotPassword({
        onSuccess: function (data) {},
        onFailure: function (err) {
          console.log(err.message || JSON.stringify(err));
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const confirmPassword = async (
    email: string,
    newPassword: string,
    authCode: string
  ) => {
    var userData = {
      Username: email,
      Pool: userPool.current,
    };
    var cognitoUser = new CognitoUser(userData);
    try {
      cognitoUser.confirmPassword(authCode, newPassword, {
        onSuccess() {
          console.log("Password confirmed!");
        },
        onFailure(err) {
          console.log("Password not confirmed!");
        },
      });
    } catch (err: any) {
      setConfirmError(err.message);
    }
  };

  const SignOut = async () => {
    var userData = {
      Username: session.username,
      Pool: userPool.current,
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.signOut();
    setSession(null);
  };
  const SignUp = async (user: any) => {
    try {
      setSignUpError(null);
      var attributeList = [];
      var dataEmail = {
        Name: "name",
        Value: user.attributes.name,
      };
      var attributeEmail = new CognitoUserAttribute(dataEmail);
      attributeList.push(attributeEmail);
     return await userPool.current.signUp(
        user.username,
        user.password,
        attributeList,
        [],
        function async(err, result) {
     
          console.log(err);
          if(result){
            console.log(result);
            setSignupSuccess(true)
            return result
         
          }
          if (err) {
            setSignUpError(err.message || JSON.stringify(err));
            return;
          }
       
          
        }
      );
    } catch (err: any) {
      console.log(err);

      setSignUpError(err.message);
      return err
    }
  };
  const setAuthSesion = async () => {
    try {
      const user = await userPool.current.getCurrentUser();
      setSession(user);
    } catch (err) {
      setSession(null);
    }
  };

  const getUserDetails = async () => {
    try {
      const cognitoUser = await userPool.current.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession(function (err: any, session: any) {
          if (err) {
            console.log(err.message || JSON.stringify(err));
            return;
          }
          cognitoUser.getUserAttributes(function (err, attributes) {
            if (err) {
              console.log(err);
            } else {
              setAttributes(attributes);
            }
          });
        });
      }
    } catch (err) {
      setSession(null);
    }
  };

  const UpdatePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const cognitoUser = await userPool.current.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession(function (err: any, session: any) {
          cognitoUser.changePassword(
            oldPassword,
            newPassword,
            function (err, result) {
              if (err) {
                console.log(err.message || JSON.stringify(err));
                return;
              }
              console.log("call result: " + result);
            }
          );
        });
      }
    } catch (err) {}
  };

  const updateUserAttributes = async (name: string) => {
    try {
      const cognitoUser = await userPool.current.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession(function (err: any, session: any) {
          var attributeList = [];
          var newAttribute = {
            Name: "name",
            Value: name,
          };
          var attribute = new CognitoUserAttribute(newAttribute);
          attributeList.push(attribute);

          cognitoUser.updateAttributes(attributeList, function (err, result) {
            if (err) {
              console.log(err.message || JSON.stringify(err));
              return;
            }
            console.log("call result: " + result);
          });
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    setAuthSesion();
    getUserDetails();
  }, []);

  const configContext = {
    session,
    Login,
    SignOut,
    signUpError,
    SignUp,
    loginError,
    signupSuccess,
    setSignupSuccess,
    setSignUpError,
    loginErrorMessage,
    forgotPassword,
    confirmPassword,
    confirmError,
    getUserDetails,
    attributes,
    updateUserAttributes,
    UpdatePassword,
    userPool,
  };

  return <Context.Provider value={configContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
