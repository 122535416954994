import {
  abergavennychronicleConfig,
  altonheraldConfig,
  bordonheraldConfig,
  breconradnorConfig,
  BudeStrattonPost,
  cambrianNewsConfig,
  chepstowbeacon,
  CornishDevonPost,
  CreditonCourier,
  DartmouthChronicle,
  DawlishGazette,
  farnhamheraldConfig,
  haslemereheraldConfig,
  Holsworth,
  IOM,
  IvybridgeSouthBrentGazette,
  KingsbridgeSalcombeGazette,
  liphookheraldConfig,
  MidDevonAdvertiser,
  MidsomerNorton,
  monmouthshirebeaconConfig,
  narberthAndWhitlandTodayConfig,
  OkehamptonTimes,
  pembrokeTodayConfig,
  petersfieldpostConfig,
  rossgazetteConfig,
  SouthHamsGazette,
  TamarValleyTimes,
  tavistockToday,
  TeignmouthPost,
  tenbyTodayConfig,
  TheCornishTimes,
  theforesterConfig,
  theforestreviewConfig,
  TotnesTimes,
  Voice,
  WellingtonWeeklyNews,
  WestSomersetFreePress,
  WokingAndDaily,
} from "common/config";
import React, { createContext, ReactNode, FC } from "react";

type Props = {
  children: ReactNode;
  pub: any;
};

const config: { [unit: string]: any } = {
  ["petersfieldpost.co.uk"]: petersfieldpostConfig,
  ["farnhamherald.com"]: farnhamheraldConfig,
  ["altonherald.com"]: altonheraldConfig,
  ["bordonherald.com"]: bordonheraldConfig,
  ["haslemereherald.com"]: haslemereheraldConfig,
  ["liphookherald.com"]: liphookheraldConfig,
  ["abergavennychronicle.com"]: abergavennychronicleConfig,
  ["monmouthshirebeacon.co.uk"]: monmouthshirebeaconConfig,
  ["chepstowbeacon.co.uk"]: chepstowbeacon,
  ["brecon-radnor.co.uk"]: breconradnorConfig,
  ["cambrian-news.co.uk"]: cambrianNewsConfig,
  ["tenby-today.co.uk"]: tenbyTodayConfig,
  ["narberth-and-whitland-today.co.uk"]: narberthAndWhitlandTodayConfig,
  ["pembroke-today.co.uk"]: pembrokeTodayConfig,
  ["theforester.co.uk"]: theforesterConfig,
  ["rossgazette.com"]: rossgazetteConfig,
  ["theforestreview.co.uk"]: theforestreviewConfig,
  ["tavistock-today.co.uk"]: tavistockToday,
  ["voicenewspapers.co.uk"]: Voice,
  ["middevonadvertiser.co.uk"]: MidDevonAdvertiser,
  ["dawlish-today.co.uk"]: DawlishGazette,
  ["teignmouth-today.co.uk"]: TeignmouthPost,
  ["dartmouth-today.co.uk"]: DartmouthChronicle,
  ["ivybridge-today.co.uk"]: IvybridgeSouthBrentGazette,
  ["kingsbridge-today.co.uk"]: KingsbridgeSalcombeGazette,
  ["southhams-today.co.uk"]: SouthHamsGazette,
  ["totnes-today.co.uk"]: TotnesTimes,
  ["tamarvalleytimes.co.uk"]: TamarValleyTimes,
  ["okehampton-today.co.uk"]: OkehamptonTimes,
  ["creditoncourier.co.uk"]: CreditonCourier,
  ["iomtoday.co.im"]: IOM,
  ["thepost.uk.com"]: CornishDevonPost,
  ["bude-today.co.uk"]: BudeStrattonPost,
  ["holsworthy-today.co.uk"]: Holsworth,
  ["cornish-times.co.uk"]: TheCornishTimes,
  ["mnrjournal.co.uk"]: MidsomerNorton,
  ["wokingnewsandmail.co.uk"]: WokingAndDaily,
  ["wellington-today.co.uk"]: WellingtonWeeklyNews,
  ["wsfp.co.uk"]: WestSomersetFreePress,
  
};

export interface publicationTypes {
  publication: string;
  config: any;
}
export const Context = createContext({} as any);

export const Provider: FC<Props> = ({ children, pub }) => {
  const configContext = {
    publication: pub,
    config: config[pub],
  };

  return <Context.Provider value={configContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
