import { createGlobalStyle } from 'styled-components';
import { defaultBreakpoints} from 'styled-media-query';

const pubConfig: { [unit: string]: string } = {
  ["petersfieldpost.co.uk"]: "#B11319",
  ["farnhamherald.com"]: "#00589F",
  ["haslemereherald.com"]: "#00589F",
  ["liphookherald.com"]: "#00589F",
  ["bordonherald.com"]: "#00589F",
  ["altonherald.com"]: "#00589F",
  ["abergavennychronicle.com"]: "#00589F",
  ["monmouthshirebeacon.co.uk"]: "#00589F",
  ["chepstowbeacon.co.uk"]: "#00589F",
  ["brecon-radnor.co.uk"]: "#00589F",
  ["cambrian-news.co.uk"]: "#B11319",
  ["tenby-today.co.uk"]: "#228747",
  ["narberth-and-whitland-today.co.uk"]: "#228747",
  ["pembroke-today.co.uk"]: "#228747",
  ["theforester.co.uk"]: "#228747",
  ["rossgazette.com"]: "#00589F",
  ["theforestreview.co.uk"]: "#228747",
  ["tavistock-today.co.uk"]: "#00589F",
  ["middevonadvertiser.co.uk"]: "#00589F",
  ["dawlish-today.co.uk"]: "#00589F",
  ["teignmouth-today.co.uk"]: "#00589F",
  ["dartmouth-today.co.uk"]: "#00589F",
  ["ivybridge-today.co.uk"]: "#00589F",
  ["kingsbridge-today.co.uk"]: "#00589F",
  ["southhams-today.co.uk"]: "#00589F",
  ["totnes-today.co.uk"]: "#00589F",
  ["tamarvalleytimes.co.uk"]: "#00589F",
  ["okehampton-today.co.uk"]: "#00589F",
  ["iomtoday.co.im"]: "#00589F",
  ["creditoncourier.co.uk"]: "#B11319",
  ["voicenewspapers.co.uk"]: "#B11319",
  ["mnrjournal.co.uk"]: "#B11319",
  ["thepost.uk.com"]: "#00589F",
  ["bude-today.co.uk"]: "#00589F",
  ["holsworthy-today.co.uk"]: "#00589F",
  ["cornish-times.co.uk"]: "#00589F",
  ["wokingnewsandmail.co.uk"]:  "#00589F",
  ["wellington-today.co.uk"]: "#00589F",
  ["wsfp.co.uk"]: "#00589F",
}

const secondaryColourConfig: { [unit: string]: string } = {
  ["petersfieldpost.co.uk"]: "#F8E8E8",
  ["farnhamherald.com"]: "#DCEFFF",
  ["haslemereherald.com"]: "#DCEFFF",
  ["liphookherald.com"]: "#DCEFFF",
  ["bordonherald.com"]: "#DCEFFF",
  ["altonherald.com"]: "#DCEFFF",
  ["abergavennychronicle.com"]: "#DCEFFF",
  ["monmouthshirebeacon.co.uk"]: "#DCEFFF",
  ["chepstowbeacon.co.uk"]: "#DCEFFF",
  ["brecon-radnor.co.uk"]: "#DCEFFF",
  ["cambrian-news.co.uk"]: "#F8E8E8",
  ["tenby-today.co.uk"]: "#F4F9F6",
  ["narberth-and-whitland-today.co.uk"]: "#F4F9F6",
  ["pembroke-today.co.uk"]: "#F4F9F6",
  ["theforester.co.uk"]: "#F4F9F6",
  ["rossgazette.com"]: "#DCEFFF",
  ["theforestreview.co.uk"]: "#F4F9F6",
  ["tavistock-today.co.uk"]: "#DCEFFF",
  ["middevonadvertiser.co.uk"]: "#DCEFFF",
  ["dawlish-today.co.uk"]: "#DCEFFF",
  ["teignmouth-today.co.uk"]: "#DCEFFF",
  ["dartmouth-today.co.uk"]: "#DCEFFF",
  ["ivybridge-today.co.uk"]: "#DCEFFF",
  ["kingsbridge-today.co.uk"]: "#DCEFFF",
  ["southhams-today.co.uk"]: "#DCEFFF",
  ["totnes-today.co.uk"]: "#DCEFFF",
  ["tamarvalleytimes.co.uk"]: "#DCEFFF",
  ["okehampton-today.co.uk"]: "#DCEFFF",
  ["iomtoday.co.im"]: "#DCEFFF",
  ["creditoncourier.co.uk"]: "#F8E8E8",
  ["voicenewspapers.co.uk"]: "#F8E8E8",
  ["mnrjournal.co.uk"]: "#F8E8E8",
  ["thepost.uk.com"]: "#DCEFFF",
  ["bude-today.co.uk"]: "#DCEFFF",
  ["holsworthy-today.co.uk"]: "#DCEFFF",
  ["cornish-times.co.uk"]: "#DCEFFF",
  ["wokingnewsandmail.co.uk"]:  "#DCEFFF",
  ["wellington-today.co.uk"]: "#DCEFFF",
  ["wsfp.co.uk"]: "#DCEFFF",
}



const handleTheme = (publication: string) => {
  return (
    {
      default: {
        fontFamily: {
          primaryFont: "'Nunito', sans-serif",
          secondaryFont: "'Koh', serif",
        },
        fontSize: {
          small: '12px',
          list: '14px',
          insiderCapsule: '16px',
        },
        fontWeight: {
          thin: 300,
          light: 400,
          normal: 500,
          heavy: 600,
          bold: 700,
          extraBold: 800,
          black: 900,
        },
        color: {
          primary: pubConfig[publication],
          background: secondaryColourConfig[publication],
          secondary: '#C80015',

          text: {
            light: "#BEBEBE",
            medium: "#515151",
            dark: "#272727",
            darkGrey: "#3F3F3F",
            white: "#FFF",
          },
          backgrounds: {
            light: "#F7F7F7",
            white: "#FFF",
            blue: "#DCEFFF",
            offWhite: "#F9F9F9",
          }

        },
        borderRadius: {
          medium: '5px',
        },
      },
    }
  )
}
const GlobalStyles = createGlobalStyle`
html,

body {
  background-color: white;
  padding: 0;
  margin: 0;
  font-family: "Nunito",sans-serif;
  color: #272727;  
}

a {
  color: inherit;
  text-decoration: none;
}

p {
    margin: 0;
}

h1 {
  font-family: "Nunito";
  font-size: 52px;
  font-style: normal;
  font-weight: 800;
  line-height: 60px;


  @media (max-width: ${defaultBreakpoints.medium}) {
    font-size: 24px;
    line-height: 32px;
  }
}

h2 {
  font-family: "Nunito";
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; 

  @media (max-width: ${defaultBreakpoints.medium}) {
    font-size: 20px;
    line-height: 24px;
  }
}

* {
  box-sizing: border-box;
}
`;

export { handleTheme, GlobalStyles };
