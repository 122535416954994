import React, {
  createContext,
  ReactNode,
  useState,
  useEffect,
  FC,
} from "react";
import { useRouter } from "next/router";

type Props = {
  children: ReactNode;
};

export interface previewTypes {
  domain: string,
  previewMode: string,
  preview: boolean
}
export const Context = createContext({} as any);

export const Provider: FC<Props> = ({ children }) => {
  const [domain, SetDomain] = useState<any>("");
  const [previewMode, SetPreviewMode] = useState<any>("");
  const [preview, setPreview] = useState<any>(false);


  const router = useRouter();

  const CheckForPreviewMode = () => {
    SetDomain(router?.query?.domain);
    setPreview(router.query.preview === "");
    SetPreviewMode(router?.query?.previewMode);
  };

  useEffect(() => {
    CheckForPreviewMode();
  }, [router]);

  const configContext = {
    domain,
    previewMode,
    preview

  };

  return <Context.Provider value={configContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
