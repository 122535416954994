import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

export type DataLayerType = {
  section?: string;
  page_type?: string;
  author?: string;
  keywords?: string;
  publish_date?: string;
  paywall?: boolean;
};

export const DataLayerContext = createContext<{
  dataLayer: DataLayerType;
  setDataLayer?: Dispatch<SetStateAction<DataLayerType>>;
}>({ dataLayer: {} });

export const DataLayerProvider: FC<{ children: any }> = ({ children }) => {
  const [dataLayer, setDataLayer] = useState({});

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayer);
  }, [dataLayer]);

  return (
    <DataLayerContext.Provider value={{ dataLayer, setDataLayer }}>
      {children}
    </DataLayerContext.Provider>
  );
};
